import {
  SET_ACTIVE_BRANCH_STATE,
  SET_ACTIVE_CLASS_TYPE,
  SET_ACTIVE_COURSE_TYPE,
  SET_ACTIVE_LEARNING_TYPE,
  SET_ACTIVE_REVISION_TYPE,
  SET_ACTIVE_SUBJECT_TYPE,
  SET_ACTIVE_ZONE,
  SET_SHOW_BRANCH_OVERLAY,
  SET_SHOW_ENROLL_OVERLAY,
  SET_SHOW_OUR_COMMUNITY_OVERLAY,
} from "../../constants/home";

const home = (state = {}, action) => {
  switch (action.type) {
    case SET_ACTIVE_LEARNING_TYPE:
      return {
        ...state,
        activeLearningType: action.payload,
      };
    case SET_ACTIVE_COURSE_TYPE:
      return {
        ...state,
        activeCourseType: action.payload,
      };
    case SET_ACTIVE_SUBJECT_TYPE:
      return {
        ...state,
        activeSubjectType: action.payload,
      };
    case SET_ACTIVE_REVISION_TYPE:
      return {
        ...state,
        activeRevisionType: action.payload,
      };
    case SET_ACTIVE_CLASS_TYPE:
      return {
        ...state,
        activeClassType: action.payload,
      };
    case SET_SHOW_OUR_COMMUNITY_OVERLAY:
      return {
        ...state,
        showOurCommunityOverlay: !state?.showOurCommunityOverlay,
        selectedOurCommunitySlide: !state?.showOurCommunityOverlay
          ? action?.payload
          : undefined,
      };
    case SET_SHOW_ENROLL_OVERLAY:
      return {
        ...state,
        showEnrollOverlay: !state?.showEnrollOverlay,
        selectedBranchName: !state?.showEnrollOverlay
          ? action?.payload?.branch
          : undefined,
        selectedCourse: !state?.showEnrollOverlay
          ? action?.payload?.course
          : undefined,
      };
    case SET_ACTIVE_BRANCH_STATE:
      return {
        ...state,
        activeBranchState: action.payload,
      };
    case SET_ACTIVE_ZONE:
      return {
        ...state,
        activeZone: action.payload,
      };
    case SET_SHOW_BRANCH_OVERLAY:
      return {
        ...state,
        isBranchOverlayOpen: action.payload.isBranchOverlayOpen,
        selectedBranch: action.payload.selectedBranch,
      };
    default:
      return state;
  }
};

export default home;
